import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { DocWrapper } from '../components/layout';
import SEO from '../components/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DocWrapper;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO lang="en" title="Teamwork App: Terms" mdxType="SEO" />
    <h3>{`Terms of Service`}</h3>
    <p>{`Effective from: 21 August 2019.`}</p>
    <p>{`Please read the following terms of services carefully before using the Talkbox Teamwork App Service. The use of these services will signify your agreement to be bound by the terms. IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS, DO NOT PROCEED.`}</p>
    <h4>{`Purpose of Agreement`}</h4>
    <p>{`This is an agreement between you or the entity that you represent (hereinafter "You" or "Your") and Talkbox Limited (hereinafter “Talkbox”) governing your use of Talkbox Teamwork App available at teamworkapp.co, Apple App Store and Google Play Store or links provided by your entity. (hereinafter “Service(s)”).`}</p>
    <h4>{`Description of Service`}</h4>
    <p>{`Talkbox Teamwork App is a communication tool for organizations or working groups with highly secured messenger system. Users in the team are managed by the administrators to form a network that allows users to exchange, shares, group announcement and collect responses via mobile devices in the form of voice, text, images or videos. You are responsible for obtaining access to the Internet and the equipments necessary to use the Services.`}</p>
    <h4>{`Modification of Terms of Service`}</h4>
    <p>{`Talkbox reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Talkbox Services (or any part thereof) with or without notice. It is your responsibility to check this Agreement periodically for changes. You continue use of or access to Talkbox Services following the posting of any changes to this Agreement constitutes acceptance of those changes. Talkbox may also, in the future, offer new services and/or features. Such new features and/or services shall be subject to the terms and conditions of this Agreement. You agree that Talkbox shall not be liable to you or to any third party for any modification, suspension or discontinuance of the Talkbox Services (or any part thereof)`}</p>
    <h4>{`Privacy`}</h4>
    <p>{`Your privacy is very important to us. We designed our privacy policy to make important disclosures to you about how we collect and use the information you share on Talkbox Teamwork. We encourage you to read the `}<a parentName="p" {...{
        "href": "/privacy"
      }}>{`privacy policy`}</a>{` and use the information it contains to help make informed decisions.`}</p>
    <h4>{`Using Talkbox Teamwork you agree to the following`}</h4>
    <ul>
      <li parentName="ul">{`You are eighteen (18) years of age or older (not applicable to Talkbox Teamwork School) and have full power and authority to enter into this Terms of Service.`}</li>
      <li parentName="ul">{`You have a valid email address.`}</li>
      <li parentName="ul">{`You will not provide any false personal information on or through the Service, or create an account for anyone other than yourself without permission.`}</li>
      <li parentName="ul">{`Your login will only be used by one person, and you will not share a single login with any other person.`}</li>
      <li parentName="ul">{`You will not share your password, let anyone else access your account, or do anything else that might jeopardize the security of your account.`}</li>
      <li parentName="ul">{`You are responsible for all software, data, text, images, voice, and other content (“Content”) posted and activity that occurs under your account (even if Content is posted by someone else using your account), and you have all rights necessary to upload all Content uploaded to the Service through your account (“Your Content”) to the Service and to grant Talkbox Teamwork the right to provide the Service in connection with Your Content.`}</li>
      <li parentName="ul">{`Your Content and your other activities in connection with the Service do not and will not violate, infringe, or misappropriate any third party’s intellectual property right, right of privacy or publicity, or other personal or proprietary right, nor does Your Content contain any matter that is defamatory, obscene, unlawful, threatening, abusive, tortious, offensive or harassing.`}</li>
      <li parentName="ul">{`You will not use the Service for any illegal purposes, and you will not violate any applicable laws (including without limitation any data, privacy or export control laws).`}</li>
      <li parentName="ul">{`You will not (a) sublicense, resell, rent, lease, transfer, assign, time share or otherwise commercially exploit the Service or make the Service available to any third party; (b) use the Service in any manner that interferes with or disrupts the integrity or performance of the Service or its components, or (c) modify, adapt or hack the Service to falsely imply any sponsorship or association with Talkbox or any of its affiliates, or otherwise attempt to gain unauthorized access to the Service or its related systems or networks.`}</li>
      <li parentName="ul">{`You will comply with any codes of conduct, policies or other notices Talkbox provides you or publishes on the Service, and you shall promptly notify Talkbox if you learn of a security breach related to the Service.`}</li>
      <li parentName="ul">{`You will not transmit any viruses or any code of a destructive nature.`}</li>
      <li parentName="ul">{`If you violate the letter or spirit of this Terms of Service, or otherwise create possible legal exposure for Talkbox or any of its affiliates, we can stop providing all or part of the Service to you. We will generally try to notify you, but have no obligation to do so. You may delete your account or uninstall the application for the Service at any time.`}</li>
    </ul>
    <h4>{`Payment Feature`}</h4>
    <p>{`The payment feature service offered in Talkbox Teamwork Apps helps you to receive payment from your customers, members or users, whether online (“Online Services”), or deducing from customers, members or users’ accounts managed in Talkbox Teamwork Apps.`}</p>
    <h4>{`Role of Talkbox in the Payment Feature`}</h4>
    <p>{`Talkbox is acted as an enabler to make payment in apps possible by enabling third party payment solution, including but not limited to, Alipay; Tap & Go and Strip, to accept payment by means of credit card, bank transfer, digital wallets or cash.`}</p>
    <h4>{`Third Party Payment Solution Provider`}</h4>
    <p>{`Talkbox may from time to time recommend, provide you with access to, or enable third party payment solution (collectively, “Payment”) for your consideration or use. Such Third Party Payment Solutions are made available only as a convenience, and your transaction, access or use of any such Third Party Payment Solution is solely between you and the applicable third party payment solution provider (“Third Party Provider”). In addition to these Terms of Service, you also agree to be bound by the additional service-specific terms applicable to services you use from, or that are provided by, Third Party Payment Solution Providers.
Any use by you of Third Party Payment Solution Services offered through the Talkbox Teamwork App is entirely at your own risk and discretion, and it is your responsibility to read the terms and conditions and/or privacy policies applicable to such Third Party Payment Solution Services before using them. In some instances, Talkbox may receive a revenue share from Third Party Payment Solution Service Providers that Talkbox recommends to you or that you otherwise engage through your use of the Services.
We do not provide any warranties or make representations to you with respect to Third Party Payment Solution Services. You acknowledge that Talkbox has no control over Third Party Payment Solution Services and shall not be responsible or liable to you or anyone else for such Third Party Payment Solution Services. The availability of Third Party Payment Solution Services, including the Talkbox Teamwork App, or the integration or enabling of such Third Party Payment Solution Services with the Services does not constitute or imply an endorsement, authorization, sponsorship, or affiliation by or with Talkbox. Talkbox does not guarantee the availability of Third Party Payment Solution Services and you acknowledge that Talkbox may disable access to any Third Party Payment Solution Services at any time in its sole discretion and without notice to you. Talkbox is not responsible or liable to anyone for discontinuation or suspension of access to, or disablement of, any Third Party Payment Solution Service. Talkbox strongly recommends that you seek specialist advice before using or relying on Third Party Payment Solution Services, to ensure they will meet your needs.
If you install or enable a Third Party Payment Solution Service for use with the Talkbox Teamwork App, you grant us permission to allow the applicable Third Party Payment Solution Provider to access your data and other Materials and to take any other actions as required for the interoperation of the Third Party Payment Solution Service, and any exchange of data or other Materials or other interaction between you and the Third Party Payment Solution Provider is solely between you and such Third Party Payment Solution Provider. Talkbox is not responsible for any disclosure, modification or deletion of your data or other Materials, or for any corresponding losses or damages you may suffer, as a result of access by a Third Party Payment Solution Service or a Third Party Payment Solution Provider to your data or other Materials.
The relationship between you and any Third Party Payment Solution Provider is strictly between you and such Third Party Payment Solution Provider, and Talkbox is not obligated to intervene in any dispute arising between you and a Third Party Payment Solution Provider.
Under no circumstances shall Talkbox be liable for any direct, indirect, incidental, special, consequential, punitive, extraordinary, exemplary or other damages whatsoever, that result from any Third Party Payment Solution Services or your contractual relationship with any Third Party Payment Solution Provider. These limitations shall apply even if Talkbox has been advised of the possibility of such damages. The foregoing limitations shall apply to the fullest extent permitted by applicable law.
You agree to indemnify and hold us and (as applicable) our parent, subsidiaries, affiliates, partners, officers, directors, agents, employees, and suppliers harmless from any claim or demand, including reasonable attorneys’ fees, arising out of your use of a Third Party Payment Solution Service or your relationship with a Third Party Payment Solution Provider.
Fees and Payments
Talkbox reserves the right at any time to modify fees for access to portions or whole of any or all of the Services. However, such modified fees shall not be charged unless your prior agreement to pay such charges is obtained. Thus, if at any time Talkbox requires a fee modification for any of the Services, you will be given reasonable advance notice and the opportunity to cancel your subscription before such charges are imposed. If you elect not to pay such fees, Talkbox shall have the right to cease providing such Service to you.`}</p>
    <h4>{`Copyright and Content Ownership`}</h4>
    <p>{`We respect your right to ownership of content created or stored by you. Unless specifically permitted by you, your use of the Services does not grant Talkbox the license to use, reproduce, adapt, modify, publish or distribute the content created by you or stored in your Account for Talkbox’s commercial, marketing or any similar purpose.`}</p>
    <h4>{`Intellectual Property Rights`}</h4>
    <p>{`The release of the Talkbox Services to you will not act as an assignment of any Intellectual Property Rights that Talkbox possesses in the same.`}</p>
    <h4>{`Disclaimer of Warranties`}</h4>
    <p>{`YOU EXPRESSLY UNDERSTAND AND AGREE THAT:`}</p>
    <p>{`YOUR USE OF THE TALKBOX SERVICES AND SOFTWARE ARE AT YOUR SOLE RISK. THE TALKBOX SERVICES AND SOFTWARE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. Talkbox AND ITS SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
TALKBOX AND ITS SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS MAKE NO WARRANTY THAT (i) THE TALKBOX SERVICES OR SOFTWARE WILL MEET YOUR REQUIREMENTS; (ii) THE TALKBOX SERVICES OR SOFTWARE WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE; (iii) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE TALKBOX SERVICES OR SOFTWARE WILL BE ACCURATE OR RELIABLE; (iv) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE TALKBOX SERVICES OR SOFTWARE WILL MEET YOUR EXPECTATIONS; AND (v) ANY ERRORS IN THE SOFTWARE WILL BE CORRECTED.
ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE TALKBOX SERVICES OR SOFTWARE IS ACCESSED AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR AND HEREBY WAIVE ANY AND ALL CLAIMS AND CAUSES OF ACTION WITH RESPECT TO ANY DAMAGE TO YOUR COMPUTER SYSTEM, INTERNET ACCESS, DOWNLOAD OR DISPLAY DEVICE, OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.
NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM TALKBOX OR THROUGH OR FROM THE TALKBOX SERVICES OR SOFTWARE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THIS AGREEMENT.
A SMALL PERCENTAGE OF USERS MAY EXPERIENCE EPILEPTIC SEIZURES WHEN EXPOSED TO CERTAIN LIGHT PATTERNS OR BACKGROUNDS ON A COMPUTER AND /OR PORTABLE DEVICE SCREEN OR WHILE USING THE TALKBOX SERVICES. CERTAIN CONDITIONS MAY INDUCE PREVIOUSLY UNDETECTED EPILEPTIC SYMPTOMS EVEN IN USERS WHO HAVE NO HISTORY OF PRIOR SEIZURES OR EPILEPSY. IF YOU, OR ANYONE IN YOUR FAMILY, HAVE AN EPILEPTIC CONDITION, CONSULT YOUR PHYSICIAN PRIOR TO USING THE TALKBOX SERVICES. IMMEDIATELY DISCONTINUE USE OF THE TALKBOX SERVICES AND CONSULT YOUR PHYSICIAN IF YOU EXPERIENCE ANY OF THE FOLLOWING SYMPTOMS WHILE USING THE TALKBOX SERVICES: DIZZINESS, ALTERED VISION, EYE OR MUSCLE TWITCHES, LOSS OF AWARENESS, DISORIENTATION, ANY INVOLUNTARY MOVEMENT, OR CONVULSIONS.
Limitation of Liability
YOU AGREE THAT TALKBOX SHALL, IN NO EVENT, BE LIABLE FOR ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, SPECIAL, PUNITIVE, OR OTHER LOSS OR DAMAGE WHATSOEVER OR FOR LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION, COMPUTER FAILURE, LOSS OF BUSINESS INFORMATION, OR OTHER LOSS ARISING OUT OF OR CAUSED BY YOUR USE OF OR INABILITY TO USE THE SERVICE, EVEN IF TALKBOX HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY DISPUTE WITH TALKBOX RELATED TO ANY OF THE SERVICES SHALL BE TERMINATION OF SUCH SERVICE. IN NO EVENT SHALL TALKBOX’S ENTIRE LIABILITY TO YOU IN RESPECT OF ANY SERVICE, WHETHER DIRECT OR INDIRECT, EXCEED THE FEES PAID BY YOU TOWARDS SUCH SERVICE.`}</p>
    <h4>{`Indemnification`}</h4>
    <p>{`You agree to indemnify and hold harmless Talkbox, its officers, directors, employees, suppliers, and affiliates, from and against any losses, damages, fines and expenses (including attorney's fees and costs) arising out of or relating to any claims that you have used the Services in violation of another party's rights, in violation of any law, in violations of any provisions of the Terms, or any other claim related to your use of the Services.`}</p>
    <h4>{`Applicable Governing Law / Jurisdiction`}</h4>
    <p>{`This Agreement constitutes the entire agreement between Talkbox and you concerning the subject matter hereof, and they may only be modified by a written amendment signed by an authorized executive of Talkbox, or by Talkbox posting a revised version of this document. Except to the extent applicable law, if any, provides otherwise, this Agreement, any access to or use of Talkbox Services will be governed by the laws of Hong Kong Special Administrative Region of the People's Republic of China, and the proper venue for any disputes arising out of or relating to any of the same will be the Courts of Hong Kong Special Administrative Region of the People's Republic of China. You and Talkbox agree that these courts shall have exclusive jurisdiction over all disputes and other matters relating to the interpretation and enforcement of this Agreement or any ancillary document executed pursuant to it. Further, you and Talkbox agree that venue shall be proper in the appropriate court set out above, and agree that they shall not contest notice from that court. Any and all disputes, claims and causes of action arising out of or relating to Talkbox Services or these Terms shall be resolved individually, without resort to any form of class action.`}</p>
    <h4>{`Termination`}</h4>
    <p>{`You agree that Talkbox may terminate your Account and access to the Service for any reason. Cause for such termination may include but not be limited to breaches or violations of these Terms of Service or the Talkbox Privacy Policy, a request by you to terminate your Account, discontinuance or material modification to the Service, unexpected technical issues or problems, extended periods of inactivity, and requests by law enforcement or other government agencies. Termination of your Talkbox Account includes elimination of access to the Service, deletion of your account information such as your e-mail ID and Password, and deletion of data in your Account as permitted or required by law.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      